import React, { Component } from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
import {
  Row,
  Col,
  Gap,
  Text,
  Section,
  Title,
  Button,
  VH,
  HpTop,
  Fill,
  SEO,
} from '../components'
import { up, addResponsivity } from '../lib/styles'
import links from '../lib/links'
import Link from '../components/Link'

const storyBp = 1506

const TextAlignGap = styled.div`
  height: 0;

  ${up('tablet')} {
    /* height: 180px; */
  }

  ${up(1250)} {
    height: 180px;
  }

  ${up(storyBp)} {
    height: 60px;
  }
  /* ${up(120)} {} */
`

const StoryLink = styled(Link)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const ImageWrapper = styled.div`
  ${({ hoverText }) => css`
    position: relative;

    ${hoverText &&
    css`
      &:after {
        content: '${hoverText}';
        position: absolute;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 10px;
        font-size: 14px;
        z-index: 1;
        /* transform: translate(0, -100%); */
        margin: 24px;
        transition: 0.2s;
        opacity: 0;
      }

      &:hover {
        &:after {
          opacity: 1;
        }
      }
    `}
  `}
`

const StoryTimeTitle = styled(Title).attrs({
  as: 'span',
  black: true,
  fontWeight: '300',
  fontSize: '106px',
  mobileFontSize: '60px',
  bp: storyBp,
})`
  ${({ bp }) => css`
    margin-bottom: 10px;
    ${up(storyBp)} {
      margin-bottom: 30px;
    }
  `}
`

class Story extends Component {
  render() {
    const {
      location: { pathname },
      data: {
        image1,
        image2,
        image3,
        image4,
        image5,
        image6,
        image7,
        image8,
        image9,
        image10,
        image11,
        image12,
        image13,
        image14,
        image15,
        image16,
        image17,
        image18,
        image19,
        image20,
      },
    } = this.props

    const content = [
      {
        image: image1,
        time: '09 / 2017',
        title: 'The First Sketch: Hybrid VTOL Concept',
        text: 'Zuri started as a visual sketch. First drawn by the company founder, Michal Illich, with some crayons borrowed from his kids. Later redrawn by a professional aerospace designer to the form you see here.',
      },
      {
        image: image2,
        time: '05 / 2018',
        title: 'First Model: Testing Flight Characteristics',
        text: 'To prove the basic idea, we built a small model with a two-meter wingspan. This was used to test the flight characteristics. Building a model using common hobby components allowed for cheap experimenting and rapid progress.',
      },
      {
        image: image3,
        time: '08 / 2018',
        title: 'Scaling Up: The Five-Meter Proof of Concept',
        text: 'Next, we wanted to test the scalability of our concept. We hired an airplane designer to create a five-meter prototype. We tested it first in horizontal mode, as an airplane. Then we added the vertical takeoff and landing system and tested the aircraft in hover mode. This model is our first unmanned aircraft registered with the local CAA authorities.',
      },
      {
        image: image4,
        time: '10 / 2018',
        title: '€600K Investment from Pale Fire Capital',
        text: 'After proving that the idea has wings, we approached Jan Barta of the PFC investment fund to consult about a fundraising strategy. He liked Zuri so much that he decided to invest €600k of the Pale Fire Capital money in the company as a convertible note.',
      },
      {
        image: image5,
        time: '01 / 2019',
        title: 'Expanding the Team with Aerospace Specialists',
        text: 'With the basic funding secured, we started to grow our team. From 6 in the autumn of 2018 we grew to 15 by the summer of 2019. Professional aerospace engineers, aerodynamics experts, software developers and other specialists joined Zuri on its mission.',
      },
      {
        image: image6,
        time: '03 / 2019',
        title: 'Kiwi.com Joins as a Strategic Partner',
        text: 'We joined forces with Kiwi.com, a very successful Czech startup, which sells airline tickets. Kiwi.com invested €200k and is helping us with their thorough knowledge of the air travel industry.',
      },
      {
        image: image7,
        time: '05 / 2019',
        title: 'Hybrid VTOL Propulsion & Battery Testing',
        text: 'Things are getting serious. We are already testing components to be used in our one-seater prototype. Choosing the right electromotors, controllers and batteries is crucial for the success of our demonstrator.',
      },
      {
        image: image8,
        time: '09 / 2019',
        title: 'Wind Tunnel Testing & Aerodynamic Refinements',
        text: 'The first year we used computational fluid dynamics (CFD), a computer simulation of aerodynamics. Then, in the summer of 2019, we 3D-printed a model and verified the aerodynamics in a wind tunnel at the Czech Aerospace Research Centre.',
      },
      {
        image: image9,
        time: '2020',
        title: 'Building Our First Experimental Aircraft',
        text: 'Currently, we are building our first experimental aircraft. It will be the first to be used as a “flying wing,” an unmanned aerial vehicle for testing all the components in hover mode. In the second phase, it will be upgraded to a full single-seater airplane.',
      },
      {
        image: image10,
        time: '12 / 2020 +',
        title: 'Ground Testing the Large-Scale Demonstrator',
        text: 'Following subscale test models, ZURI had built a large-scale technology demonstrator with an 11m wingspan. Even in the age of high-end CAD software, the team at ZURI has to verify each detail in reality. Ground tests of the technology will be finalized in June 2021, followed by hover tests in July 2021.',
      },
      {
        image: image11,
        time: '05 / 2021',
        title: 'New Angel Investors Expand Zuri’s Growth',
        text: 'Finding new partners and investors is a crucial task. So with pleasure, we welcomed on board Marek Rosa from GoodAI and Zdeněk Polách from Kaora, who recently invested a total of € 300,000 in the ZURI project and thus joined our previous investors Pale Fire Capital and Kiwi.com.',
      },
      {
        image: image12,
        time: '09 / 2021',
        title: 'First Hover Test: A Major Hybrid VTOL Milestone',
        text: 'We have achieved a true milestone in developing our hybrid VTOL aircraft. On 15th September 2021, ZURI team carried out the very first hover test. Our large-scale technological demonstrator with an 11m wingspan took off the ground and performed its first hover flight.',
      },
      {
        image: image13,
        time: '10 / 2021',
        title: '€1.3M Angel Investment Fuels Development',
        text: 'ZURI welcomes a round of angel investors on board! With a lead investment of €750k, Mr. Jaroslav Beck becomes the largest single investor in ZURI’s history. Five other angels contributed additional €550k in this round, so ZURI had raised over €2.4 million in total (including conversion of previous notes) and secured necessary financing for the current development phase.',
      },
      {
        image: image14,
        time: '01 / 2022',
        title: 'Introducing Zuri 2.0: Next Hybrid VTOL Evolution',
        text: 'ZURI officially introduces the next generation of its hybrid VTOL aircraft, designated ZURI 2.0. After hundreds of hours of development and testing of large-scale flight demonstrator and sub-scale models, the ZURI team prepared the current version of the aircraft. This version builds on the knowledge the team has gained during the previous period and takes the ZURI project to the next stage of development.',
      },
      {
        image: image15,
        time: '10 / 2022',
        title: 'Zuri Design Updates: Seating & V-Tail Enhancements',
        text: 'We unveiled updates of the Zuri 2.0 concept. The seating configuration changed to 1+2+2 - one pilot and two rows of two passengers each. The aircraft now also has a V-tail instead of the traditional tail.',
      },
      {
        image: image16,
        time: '04/2023 ',
        title: 'Zuri Showcases VR Flight Experience at Aero Friedrichshafen',
        text: 'We attended the Aero Friedrichshafen, largest general aviation event in Europe. The main attraction at our stand was a unique VR simulator for the pilot and four passengers. This device, developed by our team, provided visitors with a futuristic experience — flying in the ZURI 2.0.',
      },
      {
        image: image17,
        time: '09/2023',
        title: 'Zuri’s Ground Testbed at Aero Friedrichshafen',
        text: "Thanks to improvements in the control system and weight reduction of the ZURI TD (technological demonstrator) with its 11-meter wingspan, we achieved a record-breaking flight. This accomplishment included not only unprecedented height and flight duration but also quick responsiveness to the pilot's commands.",
      },
      {
        image: image18,
        time: '04/2024',
        title: "Zuri's “Skeleton” Ground Testbed at Aero Friedrichshafen",
        text: 'For this year’s Aero Friedrichshafen event, we prepared a special treat with a new ground testbed showcasing our latest stage of development. In addition to attracting thousands of visitors to our stand, the Skeleton also caught the attention of journalists from around the world.',
      },
      {
        image: image19,
        imageAlt: 'Photo: Michael Tomeš / Forbes',
        hoverText: 'Photo: Michael Tomeš / Forbes',
        time: '12/2024',
        title: 'Zuri Secures €3 Million Investment',
        text: (
          <p>
            Zuri has raised €3 million in an oversubscribed Pre-Series A round,
            totaling €4.4 million. This funding will accelerate the development
            of Zuri’s Hybrid VTOL aircraft and the Technology Demonstrator TD
            2.0, featuring enhanced tiltrotors and improved flight capabilities.
            The investment is backed by Jan Barta and Boris Renski, among
            others. Learn more about{' '}
            <StoryLink
              href="https://zuri.com/news/zuri-3m-investment-hybrid-vtol-pre-series-a-4-4m"
              asA
            >
              Zuri’s €3 million investment
            </StoryLink>{' '}
            in the full press release.
          </p>
        ),
      },
      {
        image: image20,
        imageAlt:
          "Zuri team members conducting a test on a hybrid VTOL aircraft prototype on a runway. The team is focused on advancing the development of vertical takeoff and landing technology, enhancing the aircraft's capabilities for future commercial and emergency transport solutions.",
        time: '02/2025',
        title: 'Zuri Expands Team to Accelerate Next-Phase Growth',
        text: (
          <p>
            Zuri is growing rapidly with key new hires including a Chief of
            Manufacturing, Chief Marketing Officer, Aerospace Designer, and
            Electronics Developer. We’re now looking to add more Aerospace
            Designers, a Stress Engineer, and a Project Manager to support our
            next phase of innovation.{' '}
            <StoryLink to={links.CAREER}>
              Explore Zuri career opportunities
            </StoryLink>{' '}
            and join our growing team.
          </p>
        ),
      },
    ]

    return (
      <>
        <SEO
          title="Our Story | Zuri"
          description="Follow Zuri’s journey from concept to reality. Learn about key milestones, investor backing, and our vision for hybrid VTOL air mobility."
          pathname={pathname}
        />
        <Gap.Top />
        <Gap gap="140px" mobileGap="40px" />
        <Section background="black">
          <Title.Big
            innerAs="h1"
            fontWeight="400"
            white
            black={false}
            uppercase
            textAlign="center"
          >
            The ZURI Story
          </Title.Big>
        </Section>
        <Gap gap="80px" mobileGap="40px" />
        <Section.Simple>
          {content.map(
            ({ image, imageAlt, time, title, text, hoverText }, key) => (
              <Row
                key={time}
                alignItems="stretch"
                responsive
                bp="desktop"
                maxWidth="1690px"
                justifyContent="space-between"
              >
                <Col
                  maxWidth="997px"
                  width="100%"
                  height="auto"
                  critical={key === 0}
                  background="black"
                >
                  <ImageWrapper hoverText={hoverText}>
                    <Img fluid={image.image.fluid} alt={imageAlt} />
                  </ImageWrapper>
                  {key !== content.length - 1 && (
                    <Fill height="38px" showAfter="desktop" />
                  )}
                </Col>
                <Gap gap="40px" mobileGap="40px" bp="desktop" />
                <Col
                  withPagePadding
                  maxWidth="510px"
                  style={{ paddingBottom: '16px', paddingTop: '16px' }}
                >
                  {/* <Gap gap="120px" mobileGap="0" bp="desktop" /> */}
                  <Col grow="1" />
                  <div>
                    <Title.Story as="h2">
                      <StoryTimeTitle>
                        {time}
                      </StoryTimeTitle>
                      {title}
                    </Title.Story>
                    <Gap gap="80px" mobileGap="40px" bp={storyBp} />
                    <Text.Small style={{ lineHeight: '30px' }}>
                      {text}
                    </Text.Small>
                    <TextAlignGap />
                    <Gap gap="0" mobileGap="80px" bp="desktop" />
                  </div>
                  <Col grow="2" />
                </Col>
              </Row>
            ),
          )}
        </Section.Simple>
      </>
    )
  }
}

export default Story

export const pageQuery = graphql`
  fragment StoryImage on File {
    image: childImageSharp {
      fluid(maxWidth: 977, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }

  query {
    image1: file(relativePath: { eq: "images/story/1.jpg" }) {
      ...StoryImage
    }
    image2: file(relativePath: { eq: "images/story/2.jpg" }) {
      ...StoryImage
    }
    image3: file(relativePath: { eq: "images/story/3.jpg" }) {
      ...StoryImage
    }
    image4: file(relativePath: { eq: "images/story/4.jpg" }) {
      ...StoryImage
    }
    image5: file(relativePath: { eq: "images/story/5.jpg" }) {
      ...StoryImage
    }
    image6: file(relativePath: { eq: "images/story/6.jpg" }) {
      ...StoryImage
    }
    image7: file(relativePath: { eq: "images/story/7.jpg" }) {
      ...StoryImage
    }
    image8: file(relativePath: { eq: "images/story/8.jpg" }) {
      ...StoryImage
    }
    image9: file(relativePath: { eq: "images/story/9.jpg" }) {
      ...StoryImage
    }
    image10: file(relativePath: { eq: "images/story/10.jpg" }) {
      ...StoryImage
    }
    image11: file(relativePath: { eq: "images/story/11.jpg" }) {
      ...StoryImage
    }
    image12: file(relativePath: { eq: "images/story/12.jpg" }) {
      ...StoryImage
    }
    image13: file(relativePath: { eq: "images/story/13.jpeg" }) {
      ...StoryImage
    }
    image14: file(relativePath: { eq: "images/story/14.jpg" }) {
      ...StoryImage
    }
    image15: file(relativePath: { eq: "images/story/15.jpg" }) {
      ...StoryImage
    }
    image16: file(relativePath: { eq: "images/story/16.jpg" }) {
      ...StoryImage
    }
    image17: file(relativePath: { eq: "images/story/17.jpg" }) {
      ...StoryImage
    }
    image18: file(relativePath: { eq: "images/story/18.jpg" }) {
      ...StoryImage
    }
    image19: file(relativePath: { eq: "images/story/19.webp" }) {
      ...StoryImage
    }
    image20: file(relativePath: { eq: "images/story/20.jpg" }) {
      ...StoryImage
    }
  }
`
